.subscribeForm {
  @apply flex flex-row justify-start items-start flex-wrap mb-1 w-full h-full relative;
}

.subscribeInput {
  @apply px-7 font-medium lg:rounded-r-none rounded-brand flex-1 bg-groupedInput;
  height: 48px;
  color: #000;
  border: 1px solid var(--baseBorder);
}

.subscribeInput:focus {
  @apply outline-none border-brand;
  box-shadow: inset 0 0 0 1px var(--brand);
}

.subscribeSubmit {
  @apply lg:rounded-l-none lg:w-fit w-full h-full lg:mt-0 mt-3;
}