.taxonItem {
  @apply text-lg font-semibold tracking-wider capitalize flex-shrink-0 mr-[70px];

  &:last-child {
    @apply mr-0;
  }
  &:hover,
  &:focus {
    @apply text-brand;
  }
}

.linkItem {
  @apply inline-block py-4 border-b-2 border-b-grayBg;

  &:active {
    @apply border-brand;
  }
}

.activeLink {
  @apply border-brand text-brand;
}

.dropdownMenu {
  @apply absolute max-h-[60vh] overflow-y-auto z-40;
}

.dropdownMenu li:hover {
  @apply bg-white;
}

.dropdownMenu li a {
  @apply inline-block text-lg text-grayText font-semibold tracking-wider capitalize px-5 py-2.5;
}

.dropdownMenuActive {
  @apply pointer-events-none cursor-none;
  color: var(--brand);

  &:hover {
    color: var(--brand);
  }
}
