.root {
  @apply text-base px-6 sm:w-full flex-1 z-20;
}

.category {
  min-width: 150px;
}
.categoryImage {
  @apply rounded-brand;
  min-width: 150px;
  min-height: 150px;
}
.categoryName {
  @apply mt-1 text-sm leading-6 text-white overflow-hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.menuItem a,
.menuItem span {
  @apply py-2 block text-white sm:text-black font-secondary;
}
.menuItem span svg path {
  @apply stroke-white sm:stroke-brand;
}
.menuItem a:hover,
.menuItem span:hover {
  opacity: 0.5;
}
.menuItem {
  position: relative;
}

.menuIcon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-35%) rotate(-90deg);
}
.menuIconReverse {
  @apply mr-3;
  transform: translateY(-35%) rotate(90deg);
}
.menuIconReverse svg path {
  @apply stroke-white sm:stroke-brand;
}
.menuIconReverse svg {
  height: initial;
}
.targetLevel {
  @apply px-4 sm:pt-16 w-full font-bold;
}

.targetHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--baseBorder);
  min-height: 45px;
  align-items: flex-start;
  margin-right: 1.5rem;
}

.menuItem:not(.isActive) {
  display: none;
}
.menuItem span {
  cursor: pointer;
}

.menuItem.isActive.openChild > span {
  display: none;
}
.menuItem.isActive {
  display: block;
}
.LayoutRoot {
  @apply relative flex-1 flex flex-col bg-brand sm:bg-white pb-6;
}

.header {
  @apply sticky top-0 pl-6 py-8 sm:pt-10 sm:pb-6 pr-2 sm:bg-white bg-brand
  flex items-center justify-between
  box-border w-full z-10;
  min-height: 66px;
}

.title {
  @apply text-3xl font-extrabold border-b px-6 pb-3 mb-4;
}

.container {
  @apply box-border;
}

@screen lg {
  .header {
    min-height: 74px;
  }
}

.cross {
  @apply h-6 w-6 cursor-pointer text-blueText sm:text-black;
  &:hover {
    color: #999999;
  }
}
