.root {
  @apply fixed inset-0 h-full z-50 box-border outline-none;
}

.sidebar {
  @apply h-full flex flex-col text-base bg-white shadow-xl overflow-y-auto overflow-x-hidden;
  -webkit-overflow-scrolling: touch !important;
}

.backdrop {
  @apply absolute inset-0 duration-100 ease-linear;
  background: rgba(33, 43, 54, 0.9);
  backdrop-filter: blur(0.8px);
  -webkit-backdrop-filter: blur(0.8px);
  z-index: 1;
}
.sidebarSection {
  @apply w-full sm:max-w-[24rem];
}
