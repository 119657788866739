.root {
  @apply relative flex items-center;
}

.list {
  @apply flex flex-row items-center h-full;
}

.item {
  @apply md:mr-5 mr-1 cursor-pointer relative transition ease-in-out duration-100 flex items-center outline-none text-black;

  &:last-child {
    @apply mr-0;
  }

  &:focus,
  &:active {
    @apply outline-none;
  }
}

.badge {
  @apply border border-neutral-50 text-white absolute rounded-full flex items-center justify-center text-xs -top-1/2 md:-top-1/4 -right-1/2 md:-right-1/4;
  background: var(--brand);
  min-width: 20px;
  min-height: 20px;
  @media (min-width: 768px) {
    min-width: 24px;
    min-height: 24px;
  }
}

.userNavItem {
  @apply flex flex-col items-center justify-center rounded-full;
  svg {
    @apply h-5 w-5 md:h-8 md:w-8;
  }
}

.iconCopperStudio {
  @apply flex flex-col items-center justify-center rounded-full;
  svg {
    @apply h-6 w-6  md:h-8 md:w-8;
  }
}

.mobileMenu {
  @apply flex lg:hidden ml-6;
}

.avatarButton:focus,
.mobileMenu:focus {
  @apply outline-none;
}
