.root {
  @apply flex flex-col w-full sm:pt-14 pt-8 text-sm;
  color: var(--footer-text-color);
}

.wrapper {
  max-width: 1561px;
  @apply flex flex-col w-full relative;
}

.watermarkWrapper {
  @apply flex flex-col items-center justify-center absolute right-0 bottom-0;
  width: 499px;
  height: 369px;

  img {
    width: 499px;
    height: 369px;
  }
  svg {
    fill: #ffffff;
  }
}

.footerTitle {
  @apply block text-xl font-bold uppercase mb-4;
  color: var(--footer-headings-color);
}

.footerNavColumn {
  @apply inline-flex flex-col items-start justify-start;
}

.footerNavColumnContacts {
  @apply w-auto lg:w-full;
}

.linkContactItem {
  @apply inline-flex justify-start items-center my-1 transition-colors font-secondary;
}

.linkContactAddressWrapper {
  align-items: flex-start;
  margin-top: 10px;
}

.linkContactAddress {
  @apply leading-6 font-secondary;
  font-family: inherit;
  white-space: pre-line;
}

.linkItemIcon {
  @apply block mr-2.5;
  width: 20px;
  height: 20px;
  fill: var(--footerIcon);
}

.footerText {
  @apply block mt-1 mb-5 font-secondary;
}

.footerSocialList {
  @apply flex flex-row justify-start items-start flex-wrap w-full;
}

.footerSocialItem {
  @apply w-11 h-11 flex flex-col justify-center items-center m-2.5;
  background-color: var(--brand);
  color: #ffffff;

  svg {
    @apply transition ease-in-out duration-100;
  }

  &:hover {
    svg {
      @apply transition scale-110 duration-100;
    }
  }

  &:first-of-type {
    @apply ml-0;
  }

  &:last-of-type {
    @apply mr-0;
  }
}

.footerBottom {
  @apply flex flex-row-reverse text-footerTextColor flex-wrap justify-between items-center w-full z-10 py-8 mt-8;
  border-top: 1px solid var(--baseBorder);
}

.footerBottomLeft {
  @apply inline-flex flex-col lg:flex-row justify-center md:justify-start md:w-auto w-full items-center font-secondary;
}

.footerBottomLinks {
  @apply inline-flex flex-row items-start justify-start flex-wrap mb-4 lg:mb-0;
}

.linkItem {
  @apply inline-flex justify-start items-start my-1 text-footerTextColor transition-colors font-secondary;

  &:hover {
    @apply text-brand;
  }
}

.linkItemDisabled {
  @apply pointer-events-none;
  color: var(--textGray);
}

.footerBottomRight {
  @media (min-width: 768px) and (max-width: 1672px) {
    margin-right: 56px;
  }

  @apply inline-flex flex-row md:justify-start justify-center md:w-auto w-full items-center flex-wrap;
}

.payItem {
  @apply relative flex flex-col justify-center items-center rounded-lg sm:m-2 m-1;
  color: var(--footer-text-color);
  height: 36px;
  width: 36px;

  img {
    height: 36px;
  }

  &:last-of-type {
    @apply mr-0;
  }
}

.payItemDiscover {
  width: 40px;
  height: 7px;
}

.link {
  & > svg {
    @apply transform duration-75 ease-linear;
  }

  &:hover > svg {
    @apply scale-110;
  }
}

.linkSelected {
  @apply font-bold text-brand;
}
