.root {
  @apply flex flex-col w-full text-lg;
  background-color: var(--grayBg);
  overflow: visible;
}

.linksWrapper {
  @apply flex items-center justify-start flex-nowrap w-full max-w-max overflow-x-scroll overflow-y-visible xl:mx-auto;
  padding: 0px 0px 0px 50px;
  overflow-y: visible;
}

.linksWrapper::-webkit-scrollbar {
  display: none;
}
